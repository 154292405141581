.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  min-height: 100%;
}
.main h1 {
  color: #FF8C00;
  font-family: "Monda", Courier, monospace;
  font-size: 5rem;
  margin-bottom: 10px;
}
.main h2 {
  font-family: "Courier New", Courier, monospace;
  font-size: 2rem;
  color: #070D0C;
  margin-left: 15px;
  margin-bottom: 0px;
  margin-top: 30px;
}
.main h3 {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.2rem;
  margin-bottom: 0px;
  margin-left: 15px;
}
.main h4 {
  font-family: "Courier New", Courier, monospace;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 15px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.main .digInput {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.main .digInput input:-webkit-autofill,
.main .digInput input:-webkit-autofill:hover,
.main .digInput input:-webkit-autofill:focus,
.main .digInput input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.main .digInput .labelDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  height: 65px;
  width: 45%;
}
.main .digInput .labelDiv:hover {
  position: relative;
}
.main .digInput .labelDiv:hover .toolTip {
  visibility: visible;
}
.main .digInput .labelDiv .label {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  color: #949494;
  font-size: 1rem;
  margin-left: 10px;
  margin-top: 35px;
}
.main .digInput .labelDiv .labelActive {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  color: #949494;
  margin-left: 0px;
  margin-top: 0px;
  font-size: 0.85rem;
}
.main .digInput .labelDiv .labelActive:hover {
  color: #FF8C00;
}
.main .digInput .labelDiv label {
  font-family: Arial, Helvetica, sans-serif;
  color: #949494;
  font-size: 1.2rem;
  font-size: 0.85rem;
  margin-bottom: 5px;
}
.main .digInput .labelDiv .toolTip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: #FDFFFE;
  color: #070D0C;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #FF8C00;
  top: -50px;
  width: -moz-max-content;
  width: max-content;
}
.main .digInput .labelDiv .toolTip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 1%;
  border-width: 5px;
  border-style: solid;
  border-color: #FF8C00 transparent transparent transparent;
}
.main .digInput .labelDiv input::-webkit-outer-spin-button,
.main .digInput .labelDiv input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main .digInput .labelDiv input[type=number] {
  -moz-appearance: textfield;
}
.main .digInput .labelDiv input:focus ~ label, .main .digInput .labelDiv select:focus ~ label {
  margin-left: 0px;
  margin-top: 0px;
  font-size: 0.85rem;
  color: #FF8C00;
}
.main .digInput .labelDiv input:hover ~ label {
  color: #FF8C00;
}
.main .digInput .labelDiv .selectBorder:hover ~ label {
  color: #FF8C00;
}
.main .digInput .labelDiv .selectBorder:focus ~ label {
  color: #FF8C00;
}
.main .wrapper {
  max-width: 1200px;
}
.main a {
  color: #FDFFFE;
}
.main .terminalRowUnset {
  color: #949494;
}
.main .terminalRowSet {
  color: #FDFFFE;
  position: relative;
}
.main .terminalRowUnsetHidden {
  display: none;
}
.main .flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.main .noPadding {
  padding-left: 0;
  padding-top: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.main .tableMargin {
  margin-top: 5px;
}
.main table {
  width: 100%;
  border-spacing: 0px;
  padding-left: 5px;
}
.main table tr .firstCell {
  width: 29%;
}
.main table tr .queryTypeClass {
  width: 14%;
}
.main table tr td {
  margin: 0px;
}
.main table tr .lastCell {
  width: 29%;
}
.main table tr .secondRow {
  padding-top: 5px;
  word-break: break-all;
}
.main table .tableRowHover:hover {
  color: #FF8C00;
  cursor: pointer;
}
.main .infoSectionH2 {
  font-size: 1.5rem;
  margin-top: 5px;
}
.main .copyIcon {
  align-self: center;
}
.main .copyIcon svg path {
  stroke: #FF8C00;
}
.main .copyIcon svg:hover {
  cursor: pointer;
}
.main .copyIcon svg:hover path {
  stroke: #FFCD8F;
}
.main .footerWrap {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
.main .footerWrap .githubIcon svg {
  width: 32px;
  height: 32px;
}
.main .footerWrap .githubIcon svg path {
  fill: #FDFFFE;
}
.main .footerWrap .githubIcon svg:hover path {
  fill: #070D0C;
}
.main .footerWrap .classroomIcon {
  margin-right: 5px;
}
.main .footerWrap .classroomIcon svg path, .main .footerWrap .classroomIcon svg rect {
  stroke: #FDFFFE;
}
.main .footerWrap .classroomIcon svg:hover {
  cursor: pointer;
}
.main .footerWrap .classroomIcon svg:hover path, .main .footerWrap .classroomIcon svg:hover rect {
  stroke: #070D0C;
}
.main .footerWrap .classroomIcon.active svg path, .main .footerWrap .classroomIcon.active svg rect {
  stroke: #070D0C;
}
.main .footerWrap .classroomIcon.active svg:hover {
  cursor: pointer;
}
.main .footerWrap .classroomIcon.active svg:hover path, .main .footerWrap .classroomIcon.active svg:hover rect {
  stroke: #FDFFFE;
}

@media screen and (min-width: 1200px) {
  body {
    justify-content: center;
    align-items: center;
  }
  .main {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main header {
    width: 100%;
  }
  .main .digInput {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 1200px;
    margin: auto;
    flex-wrap: nowrap;
  }
  .main .digInput .labelDiv._inputText {
    width: 35%;
  }
  .main .digInput .labelDiv._inputSelect {
    width: 15%;
  }
  .main .digInput .labelDiv {
    margin-right: 10px;
  }
  .main .digInput .submit {
    max-width: 20%;
  }
}/*# sourceMappingURL=style.css.map */