@import '_variables';

body {
    justify-content: center;
    align-items: center;
}
.main {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    header {
        width: 100%;
    }

    //Form input styles
    .digInput{
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 1200px;
        margin: auto;
        flex-wrap: nowrap;

        .labelDiv._inputText {
            width: 35%;
        }
        .labelDiv._inputSelect {
            width: 15%;
        }
        .labelDiv {
            margin-right: $padding*2;
        }
        .submit {
            max-width: 20%;
        }
    }

}
