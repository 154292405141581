@import '_variables';

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $margin*4;
    min-height: 100%;

    //General header styling
    h1 {
        color: $primary-orange;
        font-family: $h1;
        font-size: 5rem;
        margin-bottom: $padding*2;
    }

    h2 {
        font-family: $h2;
        font-size: 2rem;
        color: $black;
        margin-left: $margin*3;
        margin-bottom: 0px;
        margin-top: $margin*6;
    }

    h3{
        font-family: $h3;
        font-size: 1.2rem;
        margin-bottom: 0px;
        margin-left: $margin*3;
    }

    h4{
        font-family: $h3;
        font-size: 0.9rem;
        font-weight: 400;
        margin-left: $margin*3;
        margin-top: 12px;
        margin-bottom: 0px;
    }
    
    //Input form
    .digInput {
        margin-top: $padding*2;
        margin-bottom: $padding*2;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
        }

        .labelDiv {
            display: flex;
            flex-direction: column;
            text-align: center;
            border-radius:$padding;
            height: 65px;
            width: 45%;
            &:hover {
                position: relative;
                .toolTip {
                    visibility: visible;
                }
            }

            .label {
                font-family: $label;
                position: absolute;
                color: $grey;
                font-size: 1rem;
                margin-left: $margin*2;
                margin-top: $margin*7;
            }
            
            .labelActive {
                font-family: $label;
                position: absolute;
                color: $grey;
                margin-left: 0px;
                margin-top: 0px;
                font-size: 0.85rem;
                &:hover {
                    color: $primary-orange;
                }
            }
            
            label {
                font-family: $label;
                color: $grey;
                font-size: 1.2rem;
                font-size: 0.85rem;
                margin-bottom: $margin;
            }

            .toolTip {
                visibility: hidden;
                position: absolute;
                z-index: 1;
                background-color: $white;
                color: $black;
                padding: $padding*2;
                border-radius: 5px;
                border: 2px solid $primary-orange;
                top: -50px;
                width: max-content;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    left: 1%;
                    border-width: 5px;
                    border-style: solid;
                    border-color: $primary-orange transparent transparent transparent
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }

            input:focus ~ label, select:focus ~ label {
                margin-left: 0px;
                margin-top: 0px;
                font-size:0.85rem;
                color:$primary-orange;
            }

            input:hover ~ label {
                color: $primary-orange;
            }

            .selectBorder:hover ~ label {
                color: $primary-orange;
            }

            .selectBorder:focus ~ label {
                color: $primary-orange;
            }
        }


    }

    .wrapper {
        max-width: 1200px;
    }

    //Terminal styles
    a {
        color: $white;
    }
    .terminalRowUnset {
        color: $grey;
    }
    .terminalRowSet{
        color: $white;
        position: relative;
    }
    .terminalRowUnsetHidden {
        display: none;
    }
    .flexRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .noPadding{
        padding-left: 0;
        padding-top: $padding;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .tableMargin {
        margin-top: $margin;
    }
    
    table{
        width: 100%;
        border-spacing: 0px;
        padding-left: $padding;
        tr {
            .firstCell {
                width: 29%;
            }
            .queryTypeClass {
                width: 14%;
            }
            td{
                margin: 0px;
            }
            .lastCell {
                width: 29%;
            }
            .secondRow {
                padding-top: $padding;
                word-break: break-all;
            }
        }
        .tableRowHover {
            &:hover {
                color: $primary-orange;
                cursor: pointer;
            }
        }
    }

    //Info section
    .infoSectionH2 {
        font-size: 1.5rem;
        margin-top: $margin;       
    }
    .copyIcon {
        align-self: center;
        svg {
            path {
                stroke: $primary-orange;
            }
        }
        svg:hover {
            cursor: pointer;
            path {
                stroke: $secondary-orange;
            }
        }
    }
    
    //footer
    .footerWrap {
        width: 100%;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
        .githubIcon {
            svg {
                width: 32px;
                height: 32px;
                path {
                    fill: $white;
                }
                &:hover{
                    path {
                        fill: $black;
                    }
                } 
            }
        }
        .classroomIcon {
            margin-right: $margin;
            svg{
                path, rect {
                    stroke: $white;
                }
                &:hover {
                    cursor: pointer;
                    path, rect {
                        stroke: $black;
                    }        
                }
            }
        }
        .classroomIcon.active {
            svg {
                path, rect {
                    stroke: $black;
                }
                &:hover {
                    cursor: pointer;
                    path, rect {
                        stroke: $white;
                    }        
                } 
            }
        }
    }
}
