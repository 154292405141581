//general colors
$primary-orange: #FF8C00;
$primary-green: #49A671;
$secondary-orange: #FFCD8F;
$secondary-green: #51A69D;
$black: #070D0C;
$white: #FDFFFE;
$grey: #949494;
//general spacing
$padding: 5px;
$margin: 5px;
//genral fonst
$h1: 'Monda', Courier, monospace;
$h2: 'Courier New', Courier, monospace;
$h3: 'Courier New', Courier, monospace;
$label: Arial, Helvetica, sans-serif; 
